import React from 'react';
import PropTypes from 'prop-types';

const defaultContextValue = {
  data: {
    tab: 0,
  },
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.Component {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      set: this.setData, // eslint-disable-line react/no-unused-state
    };
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

ContextProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Consumer as default, ContextProviderComponent };
