import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f2f2f2',
    },
    secondary: {
      main: '#AA9486',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f2f2f2',
    },
  },
  typography: {
    fontFamily: [
      'Raleway',
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

export default theme;
