exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-allaprima-jsx": () => import("./../../../src/pages/allaprima.jsx" /* webpackChunkName: "component---src-pages-allaprima-jsx" */),
  "component---src-pages-drawings-jsx": () => import("./../../../src/pages/drawings.jsx" /* webpackChunkName: "component---src-pages-drawings-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pleinair-jsx": () => import("./../../../src/pages/pleinair.jsx" /* webpackChunkName: "component---src-pages-pleinair-jsx" */),
  "component---src-pages-portraits-jsx": () => import("./../../../src/pages/portraits.jsx" /* webpackChunkName: "component---src-pages-portraits-jsx" */),
  "component---src-pages-sculptures-jsx": () => import("./../../../src/pages/sculptures.jsx" /* webpackChunkName: "component---src-pages-sculptures-jsx" */),
  "component---src-pages-stills-jsx": () => import("./../../../src/pages/stills.jsx" /* webpackChunkName: "component---src-pages-stills-jsx" */)
}

