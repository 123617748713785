/*
  eslint-disable
  import/prefer-default-export,
  import/no-extraneous-dependencies,
  react/prop-types,
  react/jsx-filename-extension
*/
import * as React from 'react';
import TopLayout from './TopLayout';

export const wrapRootElement = ({ element }) => (
  <TopLayout>{element}</TopLayout>
);
